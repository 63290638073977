import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import noImage from "../../images/no-image.png"
import { convertToCzDate } from "../utils";

import "./News.scss"

const News = () => {
    const newsQuery = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            nodes {
                excerpt
                fields {
                    slug
                }
                frontmatter {
                    date(formatString: "MMMM DD, YYYY")
                    title
                    description
                    thumbnail
                }
            }
        }
    }`);

    const posts = newsQuery.allMarkdownRemark.nodes.filter(entity => entity.fields.slug.includes("/news-") ? entity : null);;

    /*const [cards, setCards] = useState([]);
    const [cardStyle, setCardStyle] = useState({});

    useEffect(() => {
        if (cards.length === posts.length) {
            var height = 0;
            cards.forEach(cardDiv => {
                if (cardDiv.clientHeight > height) {
                    height = cardDiv.clientHeight;
                }
            });
            setCardStyle({
                height: `${height}px`
            });
        }
    },[cards]);*/

    return (
        <section id="news-page" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                <FormattedMessage id="news.title" />
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {posts.map(post => {
                    const title = post.frontmatter.title || post.fields.slug
                    var image = post.frontmatter.thumbnail || noImage
                    var splitPath = image.split("static/")
                    if (splitPath.length > 1) {
                        image = splitPath[0] + splitPath[1]
                    }
                    const date = convertToCzDate(post.frontmatter.date);
                    return (
                        <a href={post.fields.slug} className="card-holder">
                            <div className="card">
                                <div className="card-over d-flex justify-content-center align-items-center ">
                                    <div className="text-center">
                                        <h4>Více</h4>
                                        <i className="fas fa-search"></i>
                                    </div>
                                </div>
                                <div className="row" style={{height: "50%"}}>
                                    <div className="card-body d-flex flex-column">
                                        <div className="row" style={{height: "90%"}}>
                                            <div className="align-self-stretch"><h5 className="card-title">{title}</h5></div>
                                            <div className="align-self-stretch"><p className="card-text">{post.frontmatter.description}</p></div>
                                        </div>
                                        <div className="row">
                                            <div className="align-self-stretch"><p className="card-date"><small className="text-muted">{date}</small></p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <img src={image} className="card-image"/>
                                </div>
                            </div>
                            
                        </a>
                    )})}
                </div>
            </div>
        </section>
    )
}

export default News